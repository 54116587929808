<template>
  <b-container fluid="xl">
    <vue-element-loading :active="isloading" spinner="ring" background-color="rgba(255,255,255,0.6)" size="400" color="#36F"/>
    <div v-show="items.length">
      <b-row cols="1" cols-sm="1" cols-md="2" cols-lg="3">
        <b-col v-for="(item, index) in items" :key="index">
      <b-card class="xnshadow mb-5" :body-class="item.connected?'':'offlin'" header-tag="header" :header-class="item.stp?'text-warning':'text-white'" footer-tag="footer" footer-class="text-right" :header-bg-variant="item.connected?'success':'danger'">
        <template v-slot:header>
          <p class="mb-0"><span class="ml-3 mr-4" v-b-tooltip.hover :title="item.mac">#{{item.chargerid}}</span>
          <img :src="item.sigid" style="width:20px;height:20px;" :alt="item.sigmsg" :title="item.sigmsg"/>
          </p>
        </template>
        <b-card-text>
          <b-row cols="2" class="mb-2 mr-2">
            <div><span class="btn-sm">{{item.connected?$t('message.DataTime'):$t('message.OffTime')}}</span><span style="font-size:small;" @click="gotobeeptest(item)">{{item.connected?item.beep:item.ofline}}</span></div>
            <div><b-link href class="text-decoration-none btn-sm lnk" @click="gotopow(item)">{{'PowUse'|trans}}</b-link><span style="font-size:small;" @click="resetpow(item)">{{item.pow}}</span></div>
            <div><span class="btn-sm" :class="item.ac?'text-danger':''">{{acs[item.ac]}}</span><span style="font-size:small;">{{localtime}}</span></div>
            <div><span class="btn-sm"><span @click="docmd(17,item)">{{'Dev'|trans}}</span><span @click="docmd(19,item)">{{'Tempr'|trans}}</span></span><span style="font-size:small;">{{item.tp[0]}}/{{item.tp[1]}}/{{item.tp[2]}}</span></div>
            <div class="text-secondary"><span class="btn-sm">{{'Vol&Cur'|trans}}</span><span style="font-size:small;"><span @click="changepvnv(index)">{{item.pvv}}</span>/{{item.pii}}</span></div>
            <div class="text-secondary"><span class="btn-sm"><span @click="allsw(15,item)">{{'STOP'|trans}}</span><span @click="allsw(16,item)">{{'OPEN'|trans}}</span></span><span style="font-size:small;">{{item.stp?'DN':'Fn'}}/{{item.ta==1?'OP':'Fn'}}</span></div>
          </b-row>
          <b-row cols="2" class="mb-3" style="font-size:small;">
            <div class="pt-2 pb-2">
            <b-col v-for="(left, id0) in item.left" :key="id0" style="padding-left:0px;padding-right:0px;">
              <div class="mb-2">
                <b-link href @click="toggleimax(id0,left.sw,item.mac)" class="text-decoration-none mr-2 lnk">{{id0+1}}</b-link>
                <span :class="left.sw==2?'midline':''">
                <b-link href @click="showusr(left.uid,item)" class="mr-2 lnk" :disabled="left.uid==''">
                  <b-iconstack font-scale="1.5" v-if="left.uid">
                    <b-icon stacked icon="person-fill" variant="info"></b-icon>
                    <b-icon stacked icon="circle" variant="info"></b-icon>
                  </b-iconstack>
                  <b-iconstack font-scale="1.5" v-else>
                    <b-icon stacked icon="person" variant="grey"></b-icon>
                    <b-icon stacked icon="circle" variant="grey"></b-icon>
                  </b-iconstack>
                </b-link>
                <span v-b-tooltip.hover :title="left.imax" class="mr-2">{{left.end}}</span>
                <b-link href @click="togglesw(id0,item,left.uid)" class="mr-2 lnk" :disabled="left.sw>1 || item.connected==0">
                  <b-icon :icon="sws[left.sw]" font-scale="1.5" :variant="sts[left.sw]"></b-icon>
                </b-link>
                <span @click="killuser(id0,item,left.uid)">{{left.pi}}</span>
                </span>
              </div>
            </b-col>
            </div>
            <div class="pt-2 pb-2">
            <b-col v-for="(right, id6) in item.right" :key="id6" style="padding-left:0px;padding-right:0px;">
              <div class="mb-2">
                <b-link href @click="toggleimax(id6+6,right.sw,item.mac)" class="text-decoration-none mr-2 lnk">{{id6>2?id6-3:id6+7}}</b-link>
                <span :class="right.sw==2?'midline':''">
                <b-link href @click="showusr(right.uid,item)" class="mr-2 lnk" :disabled="right.uid==''">
                  <b-iconstack font-scale="1.5" v-if="right.uid">
                    <b-icon stacked icon="person-fill" variant="info"></b-icon>
                    <b-icon stacked icon="circle" variant="info"></b-icon>
                  </b-iconstack>
                  <b-iconstack font-scale="1.5" v-else>
                    <b-icon stacked icon="person" variant="grey"></b-icon>
                    <b-icon stacked icon="circle" variant="grey"></b-icon>
                  </b-iconstack>
                </b-link>
                <span v-b-tooltip.hover :title="right.imax" class="mr-2">{{right.end}}</span>
                <b-link href @click="togglesw(6+id6,item,right.uid)" class="mr-2 lnk" :disabled="right.sw>1 || item.connected==0">
                  <b-icon :icon="sws[right.sw]" font-scale="1.5" :variant="sts[right.sw]"></b-icon>
                </b-link>
                <span @click="killuser(6+id6,item,right.uid)">{{right.pi}}</span>
                </span>
              </div>
            </b-col>
            </div>
          </b-row>
          <b-row style="font-size:small;" class="ml-0">
            <div>@{{item.act}} #{{item.pon}} {{resetflags[item.rsf]}}, @{{item.pot}} #{{item.cot}} {{'reConn'|trans}}</div>
          </b-row>
        </b-card-text>
        <template v-slot:footer>
          <b-button size="sm" variant="outline-primary" class="mr-2"
          :to="{path:'admbill0',query:{chargerid:item.chargerid,tm:new Date().getTime()}}">{{'btn_History'|trans}}</b-button>
          <b-button size="sm" variant="outline-primary" class="mr-2" :disabled="item.connected==0" @click="docmd(11,item)">{{'btn_ACT'|trans}}</b-button>
          <b-button size="sm" variant="outline-primary" class="mr-2" :disabled="item.connected==0" @click="docmd(0,item)">{{'btn_BEP'|trans}}</b-button>
          <b-button size="sm" variant="outline-primary" :disabled="item.connected==0" @click="docmd(1,item)">{{'btn_RBT'|trans}}</b-button>
        </template>
      </b-card>
        </b-col>
      </b-row>
    </div>
    <div v-show="items.length==0">
      <b-card class="xnshadow mb-5" header-tag="header" footer-tag="footer">
        <template v-slot:header>
          <h4 class="mb-0"><b-icon icon="phone"></b-icon> No charger device found</h4>
        </template>
        <b-card-text>
          <p>
            buy chargers then shows here<br/>
           </p>
        </b-card-text>
      </b-card>
    </div>
    <div v-show="maskenabled" class="fullmask" @click="closemask">
      <div class="navbar-fixed-top alert-danger text-left pt-2 pb-2" :show="alertmsg" @click="closemask">{{alertmsg}}</div>
    </div>
    <b-form inline class="mb-4">
      <b-button-group class="mr-4">
        <b-button variant="success" @click="fetchprev" :disabled="pageid==0">{{'btn_prevpage'|trans}}</b-button>
        <b-button v-if="pageid>0" variant="outline-success" :disabled="true" >{{pageid+1}}</b-button>
        <b-button variant="success" @click="fetchNext" :disabled="pageid==newpagestart.length">{{'btn_nextpage'|trans}}</b-button>
      </b-button-group>
      <b-radio-group buttons class="mr-4" v-model="online" @change="uchange" button-variant="outline-primary">
        <b-form-radio value="0">{{'opt_all'|trans}}</b-form-radio>
        <b-form-radio value="1">{{'opt_online'|trans}}</b-form-radio>
        <b-form-radio value="2">{{'opt_ofline'|trans}}</b-form-radio>
      </b-radio-group>
    </b-form>
    <b-modal v-model="modalshow" no-close-on-backdrop no-close-on-esc hide-header ok-only :ok-title="$t('message.btn_ok')">{{modalmsg}}</b-modal>
  </b-container>
</template>

<script>
  let reloades = 0;
  export default {
    name: 'admdevices0',
    created() {
      this.gettime();
    },
    mounted() {
      this.isloading = true;
      this.fetchData();
    },
    data() {
      return {
        isloading: false,
        modalshow:false,
        modalmsg: '',
        maskenabled: false,
        bigheadimgurl: '',
        alertmsg: '',
        online:0,
        acs: [this.$t('message.localtime'), this.$t('message.gnderror')],
        sts: ['secondary', 'warning', 'grey'],
        sws: ['toggle-off','toggle-on','toggle-off'],
        resetflags:[this.$t('message.ref0'),this.$t('message.ref1'),this.$t('message.ref2'),this.$t('message.ref3'),this.$t('message.ref4'),this.$t('message.ref5'),this.$t('message.ref6'),this.$t('message.ref7')],
        admnodft:false,
        localtime:'00:00:00',
        facemoney:'',
        cardcnts:'',
        expdays:'',
        items: [],
        pageid:0,
        newpagestart:[]
      };
    },
    methods: {
      gettime(){
        let nowtm = new Date();
        let hour = nowtm.getHours();
        let minu = nowtm.getMinutes();
        let secd = nowtm.getSeconds();
        if (hour<10) hour = '0'+hour;
        if (minu<10) minu = '0'+minu;
        if (secd<10) secd = '0'+secd;
        this.localtime = hour+':'+minu+':'+secd;
        if (this.$route.fullPath.indexOf('admdevices0')>0) setTimeout(this.gettime, 900);
      },
      fetchprev() {
        this.isloading = true;
        this.pageid = this.pageid-1;
      },
      fetchPage(page) {
        this.isloading = true;
        this.pageid = page;
      },
      fetchNext() {
        this.isloading = true;
        this.pageid = this.pageid+1;
      },
      uchange($event) {
        this.isloading = true;
        this.online = $event;
        this.pageid = 0;
        this.newpagestart = [];
      },
      changepvnv(idx) {
        this.items[idx].pvv = this.items[idx].pnv;
      },
      async togglesw(id,itm,uid) {
        reloades = 0;
        let val;
        if (id<6) {
          val = itm.left[id].sw*100+id;
        } else {
          val = itm.right[id-6].sw*100+id;
        }
        let lotoken = localStorage.getItem('token');
        let params = 'token='+lotoken+'&mac='+itm.mac+'&cmd=14&val='+val+'&uid='+uid;
        await this.axios.post('/admdocmd?tm='+new Date().getTime(),params);
      },
      async toggleimax(id,sw,mac) {
        reloades = 0;
        sw = Number(sw);
        if (sw==1) {
            this.modalmsg = 'Port is working, can not disabled!';
            this.modalshow = true;
        } else {
            if (sw==2) {
              let lotoken = localStorage.getItem('token');
              let theapi = 'token='+lotoken+'&mac='+mac+'&cmd=12&val='+id;
              await this.axios.post('/admdocmd?tm='+new Date().getTime(), theapi);
            } else {
              if (confirm('Do you really want to disable/enable this port?')) {
                let lotoken = localStorage.getItem('token');
                let theapi = 'token='+lotoken+'&mac='+mac+'&cmd=13&val='+id;
                await this.axios.post('/admdocmd?tm='+new Date().getTime(), theapi);
              }
            }
        }
      },
      closemask() {
        this.maskenabled = false;
        this.alertmsg = '';
      },
      async showusr(uid,itm) {
        reloades = 0;
        let cid = itm.chargerid;
        let pow = itm.pow;
        let lotoken = localStorage.getItem('token');
        let theapi = 'token='+lotoken+'&uid='+uid+'&cid='+cid;
        let axresp = await this.axios.post('/admshowusr?tm='+new Date().getTime(), theapi);
        let powuse = pow-axresp.data.powstart;
        if (powuse<0) powuse = 0;
        powuse = (powuse/1000).toFixed(3);
        this.alertmsg = axresp.data.nickname+'['+uid+']'+'['+axresp.data.chofflowi+']'+axresp.data.msg+',PowUSED:'+powuse;
        this.maskenabled = true;
      },
      funagreepow(tp,id) {
        let token = localStorage.getItem('token');
        var theapi = '/powsec?tm='+new Date().getTime()+'&token='+token+'&tp='+tp+'&id='+id;
        this.axios.get(theapi).then(axresp => {
          if (axresp.status==200) {
            var data = axresp.data;
            if (data.rc>0 && data.sec) {
              this.$router.push({name:'power',params:{tp:tp,id:id,sec:data.sec}});
            }
          }
        });
      },
      gotopow(itm) {
        var agree1 = parseInt(localStorage.getItem('agree1')) || 0;
        if (agree1 < 10) {
          var msg = 'Power usage function can be shared and visit without authorize, do you really want to open share page?';
          if (confirm(msg)) {
            localStorage.setItem('agree1',''+(agree1+1));
            this.funagreepow(0, itm.chargerid);
          }
        } else {
          this.funagreepow(0, itm.chargerid);
        }
      },
      gotobeeptest(itm) {
        if (itm.connected) {
          this.$router.push({name:'beeptest',params:{mac:itm.mac}});
        }
      },
      async docmd(id,itm) {
        reloades = 0;
        let lotoken = localStorage.getItem('token');
        let theapi = 'token='+lotoken+'&mac='+itm.mac+'&cmd='+id;
        await this.axios.post('/admdocmd?tm='+new Date().getTime(), theapi);
      },
      async killuser(id,itm,uid) {
        reloades = 0;
        let lotoken = localStorage.getItem('token');
        let theapi = 'token='+lotoken+'&mac='+itm.mac+'&cmd=22&val='+id+'&uid='+uid;
        await this.axios.post('/docmd?tm='+new Date().getTime(), theapi);
      },
      shareme() {
        let token = localStorage.getItem('token');
        let theapi = '/getsharesec?tm='+new Date().getTime()+'&token='+token;
        this.axios.get(theapi).then((axresp) => {
            if (axresp.data.rc>0) {
                this.$router.push({name:'monitr0',params:{app:axresp.data.app,sec:axresp.data.sec}});
            } else {
                this.modalmsg = axresp.data.rm;
                this.modalshow = true;
            }
        });
      },
      async allsw(id,itm) {
        reloades = 0;
        let lotoken = localStorage.getItem('token');
        let theapi = 'token='+lotoken+'&mac='+itm.mac+'&cmd='+id;
        await this.axios.post('/docmd?tm='+new Date().getTime(), theapi);
      },
      resetpow(itm) {
        reloades = 0;
        var msg = 'This function is used to reset power usage, confirm to do this?';
        if (confirm(msg)) {
          this.docmd(24,itm);
        }
      },
      async fetchData() {
        let w = window.innerWidth;
        let h = window.innerHeight;
        let lotoken = localStorage.getItem('token');
        let params = 'token='+lotoken+'&online='+this.online+'&w='+w+'&h='+h+'&r='+reloades;
        if (this.$route.query.cidstart) {
          params = params + '&cidstart='+this.$route.query.cidstart+'&cidend='+this.$route.query.cidend;
        }
        if (this.pageid>0 && this.newpagestart[this.pageid-1]) {
          params = params + '&last='+encodeURIComponent(JSON.stringify(this.newpagestart[this.pageid-1]));
        }
        let axresp = await this.axios.post('/admdevices0?tm='+new Date().getTime(), params);
        this.isloading = false;
        if (axresp.status==200) {
          let axdata = axresp.data;
          this.items = axdata.Items;
          if (axdata.LastEvaluatedKey) {
            if (this.newpagestart.length<=this.pageid) {
              this.newpagestart.push(axdata.LastEvaluatedKey);
            } else {
              this.newpagestart[this.pageid] = axdata.LastEvaluatedKey;
            }
          }
        } else {
          this.items = [];
        }
        if (this.$route.fullPath.indexOf('admdevices0')>0 && reloades<10000) {
          let reloadms = 1000;
          if (this.items.length<4) {
            reloadms = 500;
          } else {
            if (reloades<100) {
              reloadms = 800;
            } else if (reloades<500) {
              reloadms = 1000;
            } else if (reloades<1000) {
              reloadms = 2000;
            } else {
              reloadms = 3000;
            }
          }
          setTimeout(this.fetchData, reloadms);
          reloades++;
        }
      },
    }
  }
</script>
